<template>
    <LoadingSpinner v-if="loading" class="my-5" />
    <LoadingError v-else-if="error" class="vh-50" />
    <div v-else class="card">
        <div class="card-header">
            <h5 class="fs-0 my-1">Add Printer</h5>
        </div>
        <div class="card-body">
            <form @submit.prevent="submit" class="row">
                <fieldset class="col-md" :disabled="submitting">
                    <h6 class="mb-3">Available Printers</h6>
                    <div v-if="unconfiguredPrinters.length === 0" class="mt-6 text-center text-600 fs--1">
                        No printers available
                    </div>
                    <div v-for="printer in unconfiguredPrinters"
                         :key="printer.printnode_id"
                         class="custom-control custom-radio">
                        <div class="custom-control custom-radio radio-select pl-0">
                            <input type="radio"
                                   v-model="formData.printnode_id"
                                   :value="printer.printnode_id"
                                   name="printnode_id"
                                   required
                                   :id="`printer-${printer.printnode_id}`"
                                   class="custom-control-input">
                            <label :for="`printer-${printer.printnode_id}`" class="custom-control-label d-block">
                                <span class="radio-select-content py-1">
                                    <div class="font-weight-medium">{{ printer.name }}</div>
                                    <div class="opacity-75 fs--2">
                                        Connected to <span class="font-weight-medium">{{ printer.computer_name }}</span>
                                    </div>
                                </span>
                            </label>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="col-md" :disabled="!formData.printnode_id || submitting">
                    <div class="form-group">
                        <label for="add_printer_form_name">Printer Name</label>
                        <input type="text"
                               v-model="formData.name"
                               required
                               id="add_printer_form_name"
                               class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="add_printer_form_paper">Paper Size</label>
                        <select v-model="formData.paper_size"
                                required
                                id="add_printer_form_paper"
                                class="custom-select">
                            <option selected disabled value />
                            <option value="LETTER">Letter (8½"&times;11")</option>
                            <option value="LABEL">Label (4"&times;6")</option>
                        </select>
                    </div>
                    <button type="submit"
                            class="btn btn-sm btn-falcon-primary mt-3 float-md-right"
                            :disabled="submitting">
                        <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        Add Printer
                    </button>
                </fieldset>
            </form>
        </div>
    </div>
</template>

<script>
    import { createPrinter, getUnconfiguredPrinterList } from '../services/PrinterService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';

    export default {
        name: 'CreatePrinter',
        components: {
            LoadingSpinner,
            LoadingError,
        },
        data() {
            return {
                loading: true,
                error: null,
                submitting: false,
                unconfiguredPrinters: [],
                formData: {
                    printnode_id: null,
                    name: '',
                    paper_size: '',
                },
            };
        },
        watch: {
            'formData.printnode_id'(newVal) {
                const chosenPrinter = this.unconfiguredPrinters.find(p => p.printnode_id === newVal);
                this.formData.name = chosenPrinter.name;
                this.formData.paper_size = '';
            },
        },
        async mounted() {
            try {
                const { data } = await getUnconfiguredPrinterList();
                this.unconfiguredPrinters = data;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
        methods: {
            async submit() {
                this.submitting = true;
                try {
                    await createPrinter({ ...this.formData });
                    this.$router.push({ name: 'printers' });
                } catch (err) {
                    this.$alerts.danger('Unable to Add Printer',
                                        'Please check your submission for errors. If the problem persists, please contact support.');
                } finally {
                    this.submitting = false;
                }
            },
        },
    };
</script>
